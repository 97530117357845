/* Bootstrap overrides
-------------------------------------------------- */
$body: #000;
$headings-font-weight: 400 !important;
$primary: #0037b8;
$primary-light: #004680;
$gold: #b7a600;
$light: #fafafa;
$success: #1bb157;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/* Base
-------------------------------------------------- */
html {
    min-height: 100%;
    font-size: 14px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 32px;
}

.wallet-title {
    font-weight: 600 !important;
    color: $primary;
}

.wallet-description {
    font-size: 20px;
    color: $primary-light;
}

.wallet-button {
    color: $primary-light;
    font-size: 14px;
    font-weight: 600 !important;
    cursor: pointer;
}

.wallet-button p {
    margin-top: 20px;
}

.wallet-row {
    margin-bottom: 36px;
}

.form-send-width {
    width: 100%;
}

.page-wraper {
    margin-top: 64px;
}

.wrapper {
    min-height: 100vh;
}

.opacity-6 {
    opacity: 0.6;
}

.form-label {
    margin-top: 12px;
}

.dapp-icon {
    padding: 5px;
    border-radius: 50%;
    background-color: $light;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.icon-medium {
        width: 80px;
        height: 80px;
    }
}

.text-gold {
    font-size: 16px;
    color: $gold;
}

.light-bg {
    background-color: $primary-light;
    border-color: $primary-light;
}

.home-background {
    background-image: url("../img/Money.webp");
    background-size: cover;
}

.home-description {
    margin-top: 130px;
    margin-bottom: 50px;
}

.margin-home-column-left {
    margin-left: 6rem;
    margin-right: 4rem;
}

.margin-home-column-right {
    margin-right: 6rem;
    margin-left: 4rem;
}

@media only screen and (max-width: 600px) {
    .margin-home-column-left {
        margin-left: 2rem;
        margin-right: 1rem;
    }

    .margin-home-column-right {
        margin-right: 2rem;
        margin-left: 1rem;
    }
}

.home-text {
    font-size: 24px;
}

.btn-large {
    border-radius: 6px;
    padding: 16px 32px 16px 32px;
}

/* Unlock
-------------------------------------------------- */

.unlock {
    background-image: linear-gradient(
        to bottom right,
        rgba(0, 55, 184, 0.8),
        rgba(183, 166, 0, 0.8)
    );
}

.unlock-card {
    border-radius: 12px;
}

.unlock-card h4 {
    margin-top: 12px;
    margin-left: 1rem;
    font-size: 20px;
    font-weight: bold;
}

.login-footer {
    margin-top: 24px;
    margin-bottom: 12px;
}

.login-footer a {
    text-decoration: underline;
    color: black;
}

.maiar-symbol {
    width: 24px;
    margin-right: 0.9rem;
}

.maiar-app-symbol {
    height: 24px !important;
}

.ledger-symbol {
    height: 18px !important;
}

.web-wallet-symbol {
    height: 18px !important;
}

.dapp-core-ui-component .login-buttons_noExtensionButtonWrapper {
    display: none !important;
}

.login-buttons_wrapper {
    text-align: left !important;
    width: 93%;
    margin-bottom: 16px !important;
    padding: 16px !important;
    color: $primary !important;
    background-color: white !important;
    font-size: 16px !important;
    border: 1px solid #cccccc !important;
    border-radius: 8px !important;
}

.login-buttons_wrapper:hover {
    border: 1px solid $primary !important;
}

.login-buttons-wrapper {
    min-width: 50vw !important;
    padding-bottom: 8px;
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
    width: 85px;
    height: 25px;
}

.dapp-name {
    line-height: 1;
    border-left: 1px solid $gray-400;
    padding-left: 3px;
    margin-left: 4px;
}

/* Requests
-------------------------------------------------- */

.requests-table {
    margin-top: 50px;
    width: 100%;
}

.requests-table thead td {
    width: 33.33%;
    padding-bottom: 36px;
    font-weight: 600;
}

.requests-table tbody tr {
    cursor: pointer;
}

.request-new {
    color: black;
}

.request-banned {
    color: red;
}

.request-denied {
    color: $gold;
}

.request-validated {
    color: $primary;
}

.request-validated {
    color: $success;
}
.request-title {
    padding-bottom: 30px;
}
.request-table {
    border-collapse: collapse;
    margin-bottom: 20px;
}

.request-table tr td {
    border: 1px solid #cccccc;
    border-collapse: collapse;
}
.request-table td {
    padding: 8px;
}

.request-key {
    width: 150px;
}
.request-value {
    width: 400px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    margin: 0 7px;

    button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $white;

        &:hover {
            opacity: 0.9;
        }
    }
}

.transactions {
    .transaction-icon {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    &.table {
        .transaction-hash .trim {
            @include media-breakpoint-down(xl) {
                max-width: none;
            }
            @include media-breakpoint-down(lg) {
                max-width: 26rem;
            }
            @include media-breakpoint-down(md) {
                max-width: 12rem;
            }
            @include media-breakpoint-down(sm) {
                max-width: 8rem;
            }
        }

        td,
        tr {
            padding: 0.65rem;
        }

        td {
            white-space: nowrap;
            vertical-align: middle;
        }

        tr:last-of-type {
            td {
                border-bottom: 1px solid #dee2e6;
            }
        }
    }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
    cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .bg-blue .lds-ellipsis div {
        background: rgba(255, 255, 255, 0.4);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    }
}

/* Footer
-------------------------------------------------- */
footer {
    div {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        display: inline-block;
        font-weight: 400;
        line-height: 1.155rem;

        #heart {
            height: 16px;
            width: 16px;

            path {
                fill: #c00;
            }
        }
    }
}
